<template>
  <div class="box-dialog">
    <el-dialog
      width="420px"
      :title="selfData.id ? '编辑仓库信息' : '新增仓库'"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" :rules="rules" :model="selfData" label-width="100px">
        <el-form-item label="仓库名称" prop="name">
          <el-input v-model="selfData.name" size="mini" />
        </el-form-item>
        <el-form-item label="仓库区域" prop="area">
          <el-input v-model="selfData.area" size="mini" />
        </el-form-item>
        <el-form-item v-if="false" label="地区" prop="area">
          <el-cascader
            style="width: 100%"
            size="mini"
            :props="props"
            v-model="selfData.area_list"
            placeholder="请选择行政区域"
          />
        </el-form-item>
        <el-form-item label="仓库地址" prop="address">
          <el-input v-model="selfData.address" type="textarea" size="mini" />
        </el-form-item>
        <!-- <el-form-item label="快捷键" prop="shortcut_key">
          <el-input v-model="selfData.shortcut_key" size="mini" />
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    let _this = this
    return {
      selfShow: false,
      selfData: {},
      rules: {
        name: [{ required: true, message: '请输入仓库名称', trigger: 'blur' }]
      },
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node // node 节点数据
          const nodes = [] // 动态节点
          let type = level == 0 ? '0' : node.value // 0 代表第一次请求
          _this.provinceFn(type).then((res) => {
            res.forEach(function (item) {
              let area = {
                value: item.id,
                label: item.name,
                leaf: node.level >= 2 && item.has_children == false
              }
              nodes.push(area)
            })
            resolve(nodes) // 回调
          })
        }
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          let url = ''
          if (this.selfData.id) {
            url = '/admin/warehouseSetting/updateVue'
          } else {
            url = '/admin/warehouseSetting/saveVue'
          }
          this.$api({
            method: 'post',
            url: url,
            data: this.selfData
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.popupCancel()
              this.$emit('success')
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    // 获取地区
    async provinceFn(id) {
      // 获取省市区街道
      let list = []
      await this.$api({
        method: 'post',
        url: '/admin/gcvip/area/list',
        params: {
          id: id
        }
      }).then((res) => {
        list = res.data.list
      })
      return list
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
