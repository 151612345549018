<template>
  <div class="page-main">
    <div class="m-b-10">
      <el-button type="success" size="mini" @click="itemAdd">新增仓库</el-button>
    </div>

    <!-- 表格 -->
    <vxe-grid ref="refTable" v-bind="tableOptions">
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_operate="{ row }">
        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="tableOptions.pagerConfig" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 增改单位弹窗 -->
    <PopupWarehouse
      v-if="isShowPopupWarehouse"
      :show.sync="isShowPopupWarehouse"
      :data.sync="itemObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupWarehouse from './popup-warehouse'
export default {
  components: {
    PopupWarehouse
  },
  data() {
    return {
      headerFilterUrl: '/admin/warehouseSetting/searchHeard',
      tableOptions: {
        loading: true,
        size: 'mini',
        border: true,
        'max-height': this.$util.getViewHeight() - 150,
        importConfig: {
          remote: true,
          importMethod: this.importMethod,
          modes: ['insert'],
          type: ['xlsx'],
          types: ['xlsx']
        },
        exportConfig: { type: ['xlsx'], types: ['xlsx'] },
        pagerConfig: {
          ...this.$constant.page
        },
        columns: [
          {
            field: 'id',
            width: 100,
            title: '仓库编号',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'name',
            title: '仓库名称',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'area',
            title: '仓库区域',
            slots: { header: 'header_autocomplete' }
          },
          {
            field: 'address',
            title: '仓库地址',
            slots: { header: 'header_autocomplete' }
          },
          // {
          //   field: 'shortcut_key',
          //   title: '快捷键',
          //   slots: { header: 'header_autocomplete' }
          // },
          { title: '操作', width: 160, slots: { default: 'default_operate' } }
        ],
        data: []
      },
      isShowPopupWarehouse: false,
      itemDefault: {
        id: '',
        name: '',
        area: '',
        address: '',
        area_list: [],
        shortcut_key: ''
      },
      itemObj: {},
      filterData: {},
      sortData: { id: 'desc' }
    }
  },
  created() {
    this.initItemObj()
    this.getListData()
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.tableOptions.pagerConfig.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.tableOptions.loading = true
      this.$api({
        method: 'post',
        url: '/admin/warehouseSetting/list',
        data: {
          currentPage: this.tableOptions.pagerConfig.currentPage,
          pageSize: this.tableOptions.pagerConfig.pageSize,
          sortData: this.sortData,
          filterData: this.filterData
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableOptions.data = res.data.page.list
            this.tableOptions.pagerConfig.pageSize = res.data.page.pageSize
            this.tableOptions.pagerConfig.total = res.data.page.totalRow
            this.tableOptions.pagerConfig.currentPage = res.data.page.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.tableOptions.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.tableOptions.pagerConfig.currentPage = currentPage
      this.tableOptions.pagerConfig.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupWarehouse = true
      this.initItemObj()
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupWarehouse = true
      this.itemObj = this.$util.deepCopy(row)
      this.itemObj.area_list = JSON.parse(this.itemObj.area_list)
    },
    // 项-删除
    itemDelete(row) {
      this.$confirm('您确认要删除该数据?', '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/warehouseSetting/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
